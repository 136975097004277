import React, { useState, useEffect } from "react";
import newlaunchingimage from "../../Media/New Launch/laptop.webp";
import newlaunchingimagemobile from "../../Media/New Launch/hp.webp";
import "./Newlaunching.scss";
import heronew from "../../Media/4.png";
import heronewm from "../../Media/m.png";

const Newlaunching = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="newlaunchingcontainer">
      <img
        className="heronew"
        src={isMobile ? heronewm : heronew}
        alt="new launching"
      />
      <div className="title" id="newlaunch">
        New Launching
      </div>
      <div className="subtitle">
        <b>Vanica</b> Summarecon Crown Gading
      </div>
      <div className="launchingimg">
        <img className="img" alt="newlaunchingscg" src={newlaunchingimage} />
        <img
          className="img-mobile"
          alt="newlaunchingscg"
          src={newlaunchingimagemobile}
        />
      </div>
    </div>
  );
};

export default Newlaunching;

import React from "react";
import "./Vanica.scss";
import vanica7 from "../../Media/Product/vanica 7";
import vanica9 from "../../Media/Product/vanica 9";
import Card from "../../Component/Card/Card";
import data from "./data.json";
const Vanica = () => {
  const imageMap = {
    vanica7,
    vanica9,
  };
  return (
    <div className="vanicacontainer" id="vanica">
      <div className="vanicacard">
        {data.cards.map((card, index) => {
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
              styletitle={card.styletitle}
              styleprice={card.styleprice}
              iconcolor={card.iconcolor}
              stylebutton={card.stylebutton}
              cardbackground={card.stylebackground}
              texticon={card.texticon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Vanica;

import vanica9_1 from "./Vanica 9 (1).webp";
import vanica9_2 from "./Vanica 9 (2).webp";
import vanica9_3 from "./Vanica 9 (3).webp";
import vanica9_4 from "./Vanica 9 (4).webp";
import vanica9_5 from "./Vanica 9 (5).webp";
import vanica9_6 from "./Vanica 9 (6).webp";
import vanica9_7 from "./Vanica 9 (7).webp";
import vanica9_8 from "./Vanica 9 (8).webp";
import vanica9_9 from "./Vanica 9 (9).webp";
import vanica9_10 from "./Vanica 9 (10).webp";
import vanica9_11 from "./Vanica 9 (11).webp";
import vanica9_12 from "./Vanica 9 (12).webp";
import vanica9_13 from "./Vanica 9 (13).webp";
const vanica9 = [
  vanica9_1,
  vanica9_2,
  vanica9_3,
  vanica9_4,
  vanica9_5,
  vanica9_6,
  vanica9_7,
  vanica9_8,
  vanica9_9,
  vanica9_10,
  vanica9_11,
  vanica9_12,
  vanica9_13,
];

export default vanica9;

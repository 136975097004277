import "./Footer.scss";
import logo from "../../Media/SCG-P.png";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="section-footer">
      <div className="container-footer">
        <div className="footer-top">
          <div className="footer-logo">
            <HashLink smooth to="/">
              <img src={logo} alt="logo summarecon crown gading" />
            </HashLink>
            <h3 className="footer-logo-title">Marketing Gallery</h3>
            <div className="footer-logo-text">Summarecon Crown Gading</div>
          </div>
          <div className="footer-sitemap">
            <div className="footer-site-link">
              <div className="footer-menu">
                Peta Situs
                <li className="nav-item">
                  <NavHashLink smooth to="/#about" className="nav-links">
                    About us
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#promo" className="nav-links">
                    Promo
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#newlaunch" className="nav-links">
                    New Launch
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#rumah" className="nav-links">
                    Rumah
                  </NavHashLink>
                </li>
                <li className="nav-item">
                  <NavHashLink smooth to="/#rukoelit" className="nav-links">
                    Ruko
                  </NavHashLink>
                </li>
                <li className="nav-item" onClick={scrollToTop}>
                  <a href="/" className="nav-links">
                    Back to Top
                  </a>
                </li>
              </div>
              <div className="footer-map">
                <div className="footer-map-title">Lokasi</div>
                <div className="footer-map-text">
                  <FontAwesomeIcon icon={faLocationDot} className="icon" />
                  <span>
                    Setia Asih, Tarumajaya, Bekasi Regency, West Java 17215
                  </span>
                </div>
                <div className="footer-map-text">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  <span>+62 878-8810-0388</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-rights">
          © 2024 Summarecon Crown Gading . All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;

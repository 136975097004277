import React from "react";
import promoimg from "../../Media/WhatsApp Image 2024-08-21 at 23.02.25.jpeg";
import "./Promo.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
const Promo = () => {
  const promoitem = [
    "Subsidi DP",
    "Free Canopy",
    "Free TV",
    "Free AC",
    "Free Smartdoor Lock",
    "Free CCTV",
    "Free Bathub*",
    "Insentif Huni 105 Juta*",
  ];
  return (
    <div className="promocontainer" id="promo">
      <div className="promoslider">
        <img className="card-image" src={promoimg} alt="test" />
      </div>
      <div className="promoitem">
        <div className="title">Promo Terbaru :</div>
        <div className="item">
          {promoitem.map((lt6, index) => (
            <div className="poin" key={index}>
              <FontAwesomeIcon size="xl" icon={faCheckDouble} />
              &nbsp;{lt6}
            </div>
          ))}
        </div>
        <div className="buttonwa">
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=6287888100388&text=Halo+Fitri,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Promo%20Terbaru)%20https://scgcrowngading.com/&type=phone_number&app_absent=0",
                "_blank"
              )
            }
            className="wa"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
            &nbsp;Dapatkan Promo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;

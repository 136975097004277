import vanica7_1 from "./Vanica 7 (1).webp";
import vanica7_2 from "./Vanica 7 (2).webp";
import vanica7_3 from "./Vanica 7 (3).webp";
import vanica7_4 from "./Vanica 7 (4).webp";
import vanica7_5 from "./Vanica 7 (5).webp";
import vanica7_6 from "./Vanica 7 (6).webp";
import vanica7_7 from "./Vanica 7 (7).webp";
import vanica7_8 from "./Vanica 7 (8).webp";
const vanica7 = [
  vanica7_1,
  vanica7_2,
  vanica7_3,
  vanica7_4,
  vanica7_5,
  vanica7_6,
  vanica7_7,
  vanica7_8,
];

export default vanica7;
